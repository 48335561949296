import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/style/element-variables.scss'
import VueClipboard from 'vue-clipboard2'
import directives from './directives'
import filters from './filters'
import plugins from './plugins'
import { mapState, mapMutations } from 'vuex'
import { fetchMenuTree } from './api/system/menu'
import getTime from './utils/timeConversion.js'
import SvgIcon from '@/components/SvgIcon' // svg component
import BaiduMap from 'vue-baidu-map';


Vue.config.productionTip = false
Vue.use(ElementUI, {
    size: 'small'
})
Vue.use(BaiduMap, {
    ak: 'Ona5elzlpSrwOeeAI1k0EE2yHtpLoxlN'
});
// 全局引入时间戳转换成日期格式js文件
Vue.prototype.$getTime = getTime
const audioUrl = 'http://istparking.sciseetech.com/file/yunyin_web.mp3'; //工作台铃声
Vue.prototype.$audioUrl = audioUrl;
// 
// const websocketUrl='wss://y-ws.ist.wang/ws/' // 正式 
const websocketUrl = 'wss:test22.sciseetech.com/ws/' // 办公室
Vue.prototype.$websocket = websocketUrl;

// const vconsole = require('vconsole')
// Vue.prototype.$vconsole = new vconsole() // 使用vconsole

Vue.use(VueClipboard)
Vue.use(directives)
Vue.use(filters)
Vue.use(plugins)

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// register globally
Vue.component('svg-icon', SvgIcon)
new Vue({
    data: {
        loading: false
    },
    router,
    store,
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        async userInfo() {
            if (this.userInfo == null) {
                return
            }
            await this.initRoutes()
        }
    },
    methods: {
        ...mapMutations(['switchCollapseMenu']),
        // 初始化本地配置
        initLocalConfig() {
            // 菜单状态配置
            const menuStatus = window.localStorage.getItem('MENU_STATUS')
            if (menuStatus != null) {
                this.switchCollapseMenu(menuStatus === 'true')
            }
        },
        // 初始化路由
        async initRoutes() {
            if (this.loading) {
                return
            }
            this.loading = true
                // 重置菜单
            this.$store.commit('resetMenus')
                // 获取菜单
            const storeMenus = this.$store.state.menuData.list
            await fetchMenuTree()
                .then(menus => {
                    // 添加菜单
                    storeMenus.push.apply(storeMenus, menus)
                        // 添加路由
                    this.__addRouters(menus)
                        // 404
                    router.addRoute({
                        path: '*',
                        redirect: '/404'
                    })

                })
                .catch(e => {
                    throw e
                })
                .finally(() => {
                    this.loading = false
                })
        },
        // 新建路由
        __addRouters(routes, parents = []) {
            if (routes == null || routes.length === 0) {
                return
            }
            const rs = router.getRoutes()
            for (const route of routes) {
                const parentsDump = JSON.parse(JSON.stringify(parents))
                parentsDump.push(route)
                if (route.url == null || route.url === '') {
                    this.__addRouters(route.children, parentsDump)
                    continue
                }
                if (rs.findIndex(r => r.path === route.url) > -1) {
                    this.__addRouters(route.children, parentsDump)
                    continue
                }
                router.addRoute('layout', {
                    path: route.url,
                    name: route.label,
                    meta: {
                        title: route.label,
                        paths: [...parents.map(p => p.label), route.label]
                    },
                    component: () =>
                        import ('@/views' + route.url)
                })
                this.__addRouters(route.children, parentsDump)
            }
        }
    },
    async created() {
        await this.initRoutes()
            .catch(() => {})
    },
    mounted() {
        this.initLocalConfig()
    },
    render: h => h(App)
}).$mount('#app')